@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Ethiopic:wght@200;300;400;500;600;700;800;900&display=swap");

.App {
  text-align: center;
  margin-top: -2px;
}

:root {
  --cover: "----";
}

/* // Simple Scroll */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.2);
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.4);
}

::-webkit-scrollbar-thumb:active {
  background: rgba(0, 0, 0, 0.9);
}

.tiktoklogo {
  font-size: 50px;
  position: relative;
  color: #00fff8;
}

.tiktoklogo .tiktokicon:last-child {
  mix-blend-mode: multiply;
  color: #ff003b;
  position: absolute;
  left: 2px;
  top: 5px;
}
.header-menu{
  @media (max-width: 1480px) and (min-width: 900px) {
    width: 100% !important;
  }
  .menu-wrapper{
    @media (max-width: 1330px) and (min-width: 992px) {
      width: 55% !important;
      a{
        font-size: 11px;
        padding: 6px 0px;
      }
      button{
        font-size: 11px;
        padding: 6px 0px;
      }
    }
    @media (max-width: 991px) and (min-width: 900px) {
      width: 73% !important;
      margin: 0 0 0 auto !important;
      a{
        font-size: 11px;
        padding: 6px 0px;
      }
      button{
        font-size: 11px;
        padding: 6px 0px;
      }
    }
  }
  .NavbarLogo{
    @media (max-width: 991px) and (min-width: 900px) {
      max-width: 150px;
      img{
        margin-bottom: -2px !important;
      }
    }
  }
  .GetExtensionButton {
    .get-extention{
      >div.extention-on{
        font-size: 10px !important;
      }
      div{
        font-size: 16px !important;
      }
    }
  }
}
.footer {
  background: linear-gradient(180deg, #252e69, #29358f);
  color: #fff;
  line-height: 20px;
  margin-bottom: -67px;
  padding-bottom: 4px;
  padding-top: 13px;
  width: 100%;
  margin-top: 170px;
  height: 101px;
  margin-bottom: -108px;
}

.HomepageContainer {
  // transition: all .5s;
  background: linear-gradient(90deg, #002df82c 10%, #b700ff25 70%);
  background-repeat: no-repeat;

  //set svg background photo from assets folder bg.svg
  // background-image: url('./assets/bg.svg');
  //background-size: 100%;
  //set backgorund height to fit the screen
  // height: 100vh;
  width: 100%;
  position: absolute;
  background-size: cover;
  padding-top: 40px;
}

.HomepageSubtitle {
  font-weight: 200;
  font-size: 27px;
  padding: 0px 10px;
  margin-top: -18px;
}

h1 {
  font-size: 40px;
  padding: 0px 10px;
}

.UnderSubtitle {
  margin: 0;
  color: white;
  margin-bottom: -20px;
  opacity: 0.8;
}

.glasscard {
  /* From https://css.glass */
  background: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.995),
    rgba(255, 255, 255, 0.696),
    rgba(255, 255, 255, 0.741)
  );
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  //backdrop-filter: blur(7px);
  //-webkit-backdrop-filter: blur(7px);
  border: 1px solid #ffffff52;
  width: fit-content;
  height: fit-content;
  padding: 10px;
  margin: 13px;
  width: 70%;
  border-radius: 5px;
  font-family: "Roboto", sans-serif;
  border-radius: 6px;
}

.ProgressLoader {
  width: 170px;
  /* height: 170px; */
  margin: 0px auto;
  margin-inline: 10px;
  // background: rgba(245, 245, 245, 0.911);
  padding: 10px;
  border-radius: 10px;
}

.SingleProgressContainer {
  display: flex;
  justify-content: center;
  margin-bottom: -305px;
  margin-top: 101px;
}

//background: linear-gradient(45deg, #dbc1ff 0%, rgba(238, 47, 212, 0.61) 100%);
.FaqAccordion {
  background: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.995),
    rgba(255, 255, 255, 0.696),
    rgba(255, 255, 255, 0.741)
  ) !important;

  border-radius: 16px !important;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1) !important;
}

.DownloadButtonsAndPaste {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -250px;
  margin-top: -3px;
  height: min-content;
}

.ButtonsAndMainInput {
  display: flex;
  justify-content: center;
  align-items: center;
}

textarea {
  resize: vertical;
}

.QualityCard {
  width: 170px;
  color: rgb(24, 22, 22);
  border-radius: 6px;
  background-color: whitesmoke;
  box-shadow: 4px 7px 8px 0px rgba(0, 0, 0, 0.25);
  padding: 20px;
}

.QualityCards {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 10px;
  width: 70%;
  max-width: 853px;
  margin: 10px auto;
  flex-wrap: wrap;
}

.SinglePhotoVideoResults {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  flex-wrap: wrap;
  padding-block: 30px;
  z-index: 10;
  max-width: 638px;
}
@media (max-width: 800px) {
  .SinglePhotoVideoResults {
    flex-direction: column !important;
  }
}
.SingleResultsBackground {
  width: 70%;
  position: absolute;
  max-width: 644px;
  filter: blur(2px) hue-rotate(15deg) brightness(0.15);
  height: 480px;
  margin-top: -9px;
  background: linear-gradient(#eb01a5, #d13531);
  mix-blend-mode: luminosity;
  border-radius: 29px;
  box-shadow: 0px 0px 15px rgba(0, 255, 224, 0.8);
  min-height: 400px;
}

.otheroption {
  filter: blur(1px) hue-rotate(15deg) brightness(1000.55) !important;
  object-position: -19px -463px;
  mix-blend-mode: plus-lighter !important;
}

.otherotheroption {
  filter: blur(1px) hue-rotate(15deg) brightness(1000.55) !important;
  mix-blend-mode: plus-lighter !important;
  box-shadow: none;
  object-position: -19px -463px;
}

.otherotherotheroption {
  filter: blur(0px) hue-rotate(15deg) brightness(0.15);
}
.slidehow-height-fix {
  margin-bottom: 30px !important;
  margin-top: -54px !important;
}
@media (max-width: 800px) {
  .SingleResultsBackground {
    width: 90%;

    height: 900px;
  }
  .MarginOnMobile {
    margin-top: 50px;
  }
}

.BulkResultPhotoCard {
  position: relative;
  background-color: #f5f5f5;
  padding: 10px;
  border-radius: 10px;
  // width: fit-content;
  // margin: 25px;
  //mix-blend-mode: plus-lighter;
  // box-shadow: 1px 0px 5px rgba(255, 0, 242, 0.53);
  // width: 90%;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.BulkResultPhotoCard-bar {
  position: absolute;
  bottom: 20px;
  right: 10px;
  width: 50px;
  color: white;
  padding: 8px 2px;
  font-size: 14px;
  background-color: rgba(0, 0, 0, 0.7);
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  display: none;
}
img{
  cursor: pointer;
}
.column-item{
  padding: 15px 0px 15px 0px;
}
.column-item-play{
  padding: 15px 0px 15px 0px;
  position: absolute;
  bottom: 25px;
  left: 0px;
  width: 50px;
  color: white;
  padding: 8px 2px;
  font-size: 14px;
  background-color: rgba(0, 0, 0, 0.7);
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  display: none;
}

.QualityContainer {
  background-color: #f5f5f5;
  padding: 50px;
  // box-shadow: 1px 0px 5px rgba(255, 0, 242, 0.53);
  width: 70%;
  max-width: 1200px;
  margin: 50px auto;
  border-radius: 20px;

  .main-highlights {
    // margin-top: 70px !important;
    display: flex;
    justify-content: space-between;
  }

  .main-highlights .responsive {
    max-width: 250px;
  }
  @media (max-width: 1050px) {
    .responsive {
      margin: auto;
      margin-bottom: 80px;
    }
    .responsive:last-child {
      margin: auto;

      margin-bottom: -15px !important;
    }
  }

  .is-center {
    text-align: center;
  }

  .u-m-bottom {
    margin-bottom: 0.5em;
  }

  .main-highlights .content {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    text-align: center;
    color: #252e69;
  }

  img {
    border-style: none;
  }

  .main-highlights .responsive img {
    // height: 125px;
  }

  h4 {
    color: #000;
  }

  .u-bigger-text {
    font-size: 1.1em;
  }

  .text-color--main {
    color: #0844a4;
  }

  .main-highlights h4 {
    font-style: normal;
    font-weight: 900;
    font-size: 1.5em;
    line-height: 1.1em;
    text-align: center;
    color: #252e69;
    margin-bottom: 15px;
    display: inline-block;
    min-height: 60px;
  }
}

.HowToBoxContainer {
  background-color: #f5f5f5;
  padding: 50px;
  //box-shadow: 1px 0px 5px rgba(255, 0, 242, 0.53);
  width: 70%;
  max-width: 1200px;
  margin: 50px auto;
  border-radius: 20px;

  .text {
    padding-top: 20px;
  }

  .text__container {
    margin: 0 auto;
    max-width: 1170px;
  }

  .blue__block {
    width: fit-content !important;
    background: #5940f1;
    box-shadow: 0 84px 84px -56px rgba(89, 64, 241, 0.3);
    border-radius: 32px;
    max-width: 950px;
    padding: 70px 90px;
    margin: 0 auto;
    margin-top: 50px;
    margin-bottom: 50px;
    counter-reset: css-counter 0;
  }

  @media (min-width: 950px) {
    .blue__block {
      margin-left: auto;
      margin-right: auto;
    }
  }

  h2 {
    color: #000;
  }

  h2 {
    font-style: normal;
    font-size: 2em;
    line-height: 2em;
    color: #252e69;
    text-align: center;
  }

  .text__desc {
    font-style: normal;
    font-weight: 400;
    font-size: 1.3em;
    color: #252e69;
    text-align: left;
  }

  .info-arrow {
    font-style: italic;
    font-weight: 400;
    font-size: 24px;
    line-height: 120%;
    color: #ce00ff;
    max-width: 50%;
    position: relative;
    margin-top: 10px;
    padding-right: 100px;
  }

  h3 {
    color: #000;
  }

  .blue__block h3 {
    font-style: normal;
    font-size: 1.8em;
    color: #fff;
    margin-bottom: 10px;
    margin-top: 0;
  }

  .blue__block ol {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  img {
    border-style: none;
  }

  .info-arrow img {
    position: absolute;
    right: 0;
  }

  .blue__block ol li {
    font-style: normal;
    font-size: 24px;
    color: #fff;
    margin-bottom: 0;
    position: relative;
    counter-increment: css-counter 1;
    z-index: 1;
    padding-left: 20px;
    padding-top: 40px;
  }

  .blue__block ol li:before {
    content: counter(css-counter);
    font-style: normal;
    font-weight: 900;
    font-size: 80px;
    color: #252e69;
    opacity: 0.3;
    position: absolute;
    left: -10px;
    top: -10px;
    z-index: -1;
  }

  b {
    font-weight: bolder;
  }

  .blue__block ol li b {
    display: block;
    font-weight: 900;
    z-index: 1;
  }
}

.FaqContainer {
  background-color: #f5f5f5;
  padding: 50px;
  // box-shadow: 1px 0px 5px rgba(255, 0, 242, 0.53);
  width: 70%;
  max-width: 1200px;
  margin: 50px auto;
  border-radius: 20px;
}

.BulkResultPhotos {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: center;
  width: fit-content;
  background: #181a172e;
  margin: auto;
  border-radius: 10px;
  width: 60%;
  margin-top: 20px;
  padding: 15px 0px 15px ;
  // background: linear-gradient(45deg, rgba(255, 255, 255, 0.995), rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.9));
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);

}

.BulkResultPhotoCard-icon{
  flex-direction: column;
}

.MainPart {
  background: linear-gradient(90deg, #3f0bfb 10%, #b82fee 70%);
  background-repeat: no-repeat;
  padding: 50px;
}

.GetExtensionButton {
  //background: black;
  color: whitesmoke;
  width: 226px;
  display: flex;
  align-items: center;
  width: 227px;
  justify-content: space-between;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root {
  // padding: 20px !important;
  margin-block: 20px !important;
}

.css-2ulfj5-MuiTypography-root {
  color: #252e69 !important;
  font-weight: 700 !important;
  font-size: 17px !important;
}

.AccordionText {
  font-size: 17px !important;
  color: #252e69 !important;
  text-align: left !important;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root {
  box-shadow: none !important;
  border-radius: 10px !important;
  border: none !important;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root:before {
  content: none !important;
}

.css-67l5gl::before {
  content: none !important;
}

.css-yw020d-MuiAccordionSummary-expandIconWrapper {
  color: #000060 !important;
  font-size: 19px !important;
}

.css-1fx8m19 {
  color: #000060 !important;
  font-size: 19px !important;
}

.GradientButton {
  ._9o26 {
    align-items: center;
    background: transparent;
    border: none;
    color: #000;
    display: inline-flex;
    font-size: 14px;
    justify-content: center;
    letter-spacing: 0;
    min-height: 20px;
    min-width: 75px;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: color 500ms cubic-bezier(0, 0.61, 0.28, 0.92),
      filter 500ms cubic-bezier(0, 0.61, 0.28, 0.92),
      -webkit-filter 500ms cubic-bezier(0, 0.61, 0.28, 0.92);
    white-space: nowrap;
    z-index: 0;
  }

  ._9l2w > * {
    margin-left: 16px;
    margin-right: 16px;
  }

  ._9l2w > :last-child {
    margin-right: 0;
  }

  ._9l2w ._9o26 {
    max-width: 327px;
  }

  ._9o26:hover {
    color: #fff;
    text-decoration: none;
  }

  @media (min-width: 801px) {
    ._9aos {
      font-size: 18px;
      line-height: 30px;
    }
  }

  ._9aos._3cr5 {
    color: #4080ff;
  }

  a:hover {
    text-decoration: underline;
  }

  ._1s6a._3cr5:hover {
    color: #4267b2;
    text-decoration: none;
  }

  img {
    border: 0;
  }

  ._9o2u {
    height: 100%;
    left: 0;
    max-width: inherit;
    position: absolute;
    top: 0;
    transition: opacity 500ms;
    transition-timing-function: cubic-bezier(0, 0.61, 0.28, 0.92);
    width: 100%;
    z-index: -1;
  }

  ._9o2w {
    opacity: 0;
  }

  ._8gj0 {
    height: 60px;
    left: 0;
    position: absolute;
    top: 0;
    transition: opacity 500ms;
    transition-timing-function: cubic-bezier(0, 0.61, 0.28, 0.92);
    width: 100%;
    z-index: -1;
  }

  ._8gj2 {
    opacity: 0;
  }

  ._9o26 ._9o2u {
    height: 100%;
    margin: 0;
  }

  ._a742 ._9pju {
    height: 100%;
  }

  ._9o26:hover ._9o2w {
    opacity: 1;
  }

  ._a742 {
    display: inline-block;
    //  background-image: linear-gradient( 222.35deg, #d300c5 -24.77%, #f00679 41.3%, #ff7a00 111.8%);
  }

  ._9o26._a742 {
    -webkit-background-clip: text;
    border: 1px solid transparent;
    border-radius: 40px;
    -webkit-text-fill-color: transparent;
  }

  ._9l2w ._9o26 {
    letter-spacing: 0;
    width: 100%;
    height: 70px;
    max-width: 230px;
  }

  ._9l2w {
    font-size: 16px;
  }

  :hover {
    // background: #3b5998;
    background: transparent;
  }

  ._9o26._a742 :hover {
    background-clip: unset;
    background-origin: border-box;
    border-radius: 40px;
    box-shadow: unset;
    color: inherit;
    -webkit-text-fill-color: unset;
  }

  ._9o26:hover ._9o2x {
    opacity: 0;
  }

  /*.sp_ne2We4-LKTS { 
      background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yr/r/oqeoqm6BMfN.png); 
      background-size: auto; 
      background-repeat: no-repeat; 
      display: inline-block; 
      height: 24px; 
      width: 24px; 
      background-repeat: no-repeat; 
  } */

  i.img {
    -ms-high-contrast-adjust: none;
  }

  .sp_ne2We4-LKTS.sx_3c1ac7 {
    background-position: 0 -225px;
  }
}

.GradientButtonText {
  font-size: 16px;
  font-weight: 700;
  padding: 40px 37px;
  background-image: url(https://media.istockphoto.com/photos/dotted-grid-paper-background-texture-seamless-repeat-pattern-picture-id1320330053?b=1&k=20&m=1320330053&s=170667a&w=0&h=XisfN35UnuxAVP_sjq3ujbFDyWPurSfSTYd-Ll09Ncc=) !important;
  background-position: 0;

  //font-size: 72px;
  //background: linear-gradient(#eee, #333);
  background-clip: inherit !important;
  background-origin: border-box !important;
  border-radius: 40px !important;
  box-shadow: unset !important;
  color: inherit !important;
  -webkit-text-fill-color: unset !important;
}

.GradientButtonText:hover {
  //  background-clip: text;
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/ys/r/t-mEQ1-Zrdi.svg) !important;
  background-position: 0;

  //font-size: 72px;
  //background: linear-gradient(#eee, #333);
  background-clip: inherit !important;
  background-origin: border-box !important;
  border-radius: 40px !important;
  box-shadow: unset !important;
  color: inherit !important;
  -webkit-text-fill-color: unset !important;
}

.GradientButtonIcon {
  color: white;
  margin-left: 10px;
  margin-right: -10px;
  font-size: 18px;
  margin-bottom: -2px;
}
.GradientButtonIcon:hover {
  color: #ee00ff !important;
}

.GradientButtonText:hover > .GradientButtonIcon {
  color: #ee00ff;
}

#widget4 {
  border-radius: 3% !important;
  width: 100%;
  max-width: 640px;
  // background: #5940f1;
  //padding: 55px;
  // margin-block: 70px;
}

.VideoContainer {
  max-width: 672px;
  margin: auto;
  background-color: #5940f1;
  border-radius: 20px;
  padding: 5% 5%;
  margin-block: 57px 10px;
}
#widget2 {
  border-radius: 3% !important;
  width: 100%;
  max-width: 640px;
}
#widget10 {
  width: 100%;
}
* {
  //border : 1px solid red;
}
@media (max-width: 900px) {
  .HideDonwloadButtonOnMobile {
    // display: none!important;
    opacity: 0;
    pointer-events: none;
  }
  .maininputfield {
    margin-left: -67px;
  }
  .PasteButton {
    position: absolute !important;
    right: 40px !important;
  }
  .UnderSubtitle {
    margin-top: 50px !important;
  }
  .HomepageSubtitle {
    font-size: 20px !important;
  }
  h1 {
    font-size: 30px !important;
  }
}
@media (min-width: 900px) {
  .HideDonwloadButtonOnDesktop {
    display: none !important;
  }
}

.NavbarLogo {
  max-width: 200px;
}
@media (max-width: 900px) {
  .NavbarLogo {
    position: absolute;
  }
}

@media (max-width: 900px) {
  .HideLogoOnMobile {
    display: none !important;
  }
  .NavbarLogo {
    max-width: 150px;
  }
}

@media (min-width: 900px) {
  .HideLogoOnDesktop {
    display: none !important;
  }
}

@media (max-width: 900px) {
  .HideExtensionOnMobile {
    display: none !important;
  }
}

@media (min-width: 900px) {
  .HideExtensionOnDesktop {
    display: none !important;
  }
}

body {
  padding: 0;
  margin-top: 0;
}

.LinesAnimation {
  body {
    margin: 0;
    padding: 0;
    overflow: hidden;
  }
  .background {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #edf3f8;
    background: -moz-radial-gradient(
      center,
      ellipse cover,
      #edf3f8 1%,
      #dee3e8 100%
    );
    background: -webkit-radial-gradient(
      center,
      ellipse cover,
      #edf3f8 1%,
      #dee3e8 100%
    );
    background: radial-gradient(ellipse at center, #edf3f8 1%, #dee3e8 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#edf3f8', endColorstr='#dee3e8',GradientType=1 );
  }

  .pentahedron {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    fill: #3edbf7;
  }

  .point {
    fill: #000000;
  }

  .rhombus {
    fill: #00f7ff;
    stroke: #00f2ea;
  }

  .x {
    fill: rgb(255, 0, 76);
  }

  .circle {
    fill: #11090c;
  }

  svg {
    display: block;
    width: 30px;
    height: 30px;
    position: absolute;
    transform: translateZ(0px);
  }
}

.TableIcons {
  margin-bottom: -2px;
  color: rgb(0, 183, 255);
}

.DashboardCard {
  background-color: white;
  border-radius: 1rem;
  box-shadow: rgb(0 0 0 / 10%) 0px 1px 3px 0px, rgb(0 0 0 / 6%) 0px 1px 2px 0px;
  padding: 1.25rem;
  min-height: 165px;
  margin: auto;
  margin-block: 20px;
}
.TotalNumbersTitle {
  font-size: 13px;
  opacity: 0.8;
  line-height: 1.75rem;
  font-weight: 500;
  letter-spacing: -0.025em;
  line-height: 1.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #47505f;
  text-align: left;
  margin: 0;
}
.DashboardCardNumberTitle {
  color: #feb019;
  margin-top: -5px;
  // font-size: 30px;
}
.DashboardPage {
  font-family: "Noto Sans Ethiopic", sans-serif !important;
}

.QuickViewCards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 90%;
  margin: auto;
  max-width: 900px;
}

.RotationAnimation {
  opacity: 0.7 !important;
  animation: rotation 2.2s infinite linear;
}

@keyframes rotation {
  from {
    rotate: (0deg);
  }
  to {
    rotate: (359deg);
  }
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 23px;
  height: 23px;
  margin: 8px;
  border: 3px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.DashboardComponentTitle {
  text-align: left;
  width: 90%;
  margin: 60px auto auto;
  color: rgb(71, 80, 95);
  font-weight: 500 !important;
}

@keyframes ldio-zxrz71mlja-r {
  0%,
  100% {
    animation-timing-function: cubic-bezier(0.2 0 0.8 0.8);
  }
  50% {
    animation-timing-function: cubic-bezier(0.2 0.2 0.8 1);
  }
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes ldio-zxrz71mlja-s {
  0%,
  100% {
    animation-timing-function: cubic-bezier(0.2 0 0.8 0.8);
  }
  50% {
    animation-timing-function: cubic-bezier(0.2 0.2 0.8 1);
  }
  0% {
    transform: translate(-30px, -30px) scale(0);
  }
  50% {
    transform: translate(-30px, -30px) scale(1);
  }
  100% {
    transform: translate(-30px, -30px) scale(0);
  }
}
.ldio-zxrz71mlja > div {
  transform: translate(0px, -15px);
}
.ldio-zxrz71mlja > div > div {
  animation: ldio-zxrz71mlja-r 1s linear infinite;
  transform-origin: 100px 100px;
}
.ldio-zxrz71mlja > div > div > div {
  position: absolute;
  transform: translate(100px, 82px);
}
.ldio-zxrz71mlja > div > div > div > div {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #00f2ea;
  animation: ldio-zxrz71mlja-s 1s linear infinite;
}
.ldio-zxrz71mlja > div > div:last-child {
  animation-delay: -0.5s;
}
.ldio-zxrz71mlja > div > div:last-child > div > div {
  animation-delay: -0.5s;
  background: #ff0050;
}
.loadingio-spinner-interwind-flyom1cz6sv {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  background: #ffffff;
}
.ldio-zxrz71mlja {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-zxrz71mlja div {
  box-sizing: content-box;
}
